/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

#toast-container > div {
  opacity: 0.9 !important;
  border-radius: 2px 0px 0px 2px !important;
  padding: 10px 10px 10px 50px !important;
  font-size: 0.95rem !important;
  background-size: 18px !important;
  box-shadow: none !important;
  transition: 100ms opacity;
}
toast-container > :hover {
  opacity: 1 !important;
  cursor: pointer;
}
.toast-title {
  margin-bottom: 5px !important;
}

.toast-error {
  background-color: #e54d42 !important;
  border-right: 5px solid #b82e24;
}

.toast-warning {
  background-color: #ffc107 !important;
  border-right: 5px solid #ffa000;
}

.toast-success {
  background-color: #4caf50 !important;
  border-right: 5px solid #388e3c;
}

.toast-info {
  background-color: #2196f3 !important;
  border-right: 5px solid #1976d2;
}

.pricing-plan {
  &:hover {
    .pricing-amount {
      background-color: #4c51bf;
      color: #fff;
    }
  }
}

.loader {
  width: 30px;
  height: 30px;
  border: 5px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
